var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary-container"},[_c('FrsRouterBackLink',{attrs:{"target":_vm.createParallelRoute('mapManagement')},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('IxRes',[_vm._v("mm.buttons.backToMapManagement")])]},proxy:true}])}),_c('div',{staticClass:"table-container"},[(_vm.data)?_c('SimpleTable',{staticClass:"summary-table",attrs:{"columns":_vm.columnsSummary,"data":_vm.summaryData},scopedSlots:_vm._u([{key:"productName",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}},{key:"sum",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(_vm.$i18n.format(value))+"\n      ")]}},{key:"unit",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}}],null,false,2908112349)}):_vm._e(),(_vm.data)?_c('SimpleTable',{staticClass:"details-table",attrs:{"columns":_vm.columnsDetails,"data":_vm.data},scopedSlots:_vm._u([{key:"fieldName",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}},{key:"orgUnitName",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}},{key:"area",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(_vm.$i18n.format(value, 'hectare'))+"\n      ")]}},{key:"productName",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}},{key:"value",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(_vm.$i18n.format(value))+"\n      ")]}},{key:"unit",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}}],null,false,3614511233)}):_vm._e()],1),_c('FrsLoadingIndicator',{attrs:{"requests":['mapManagement.ProductSummary']}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="summary-container">
    <FrsRouterBackLink :target="createParallelRoute('mapManagement')">
      <template #text>
        <IxRes>mm.buttons.backToMapManagement</IxRes>
      </template>
    </FrsRouterBackLink>

    <div class="table-container">
      <SimpleTable
        v-if="data"
        :columns="columnsSummary" :data="summaryData"
        class="summary-table"
      >
        <template #productName="{value}">
          {{ value }}
        </template>
        <template #sum="{value}">
          {{ $i18n.format(value) }}
        </template>
        <template #unit="{value}">
          {{ value }}
        </template>
      </SimpleTable>

      <SimpleTable
        v-if="data"
        :columns="columnsDetails" :data="data"
        class="details-table"
      >
        <template #fieldName="{value}">
          {{ value }}
        </template>
        <template #orgUnitName="{value}">
          {{ value }}
        </template>
        <template #area="{value}">
          {{ $i18n.format(value, 'hectare') }}
        </template>
        <template #productName="{value}">
          {{ value }}
        </template>
        <template #value="{value}">
          {{ $i18n.format(value) }}
        </template>
        <template #unit="{value}">
          {{ value }}
        </template>
      </SimpleTable>
    </div>

    <FrsLoadingIndicator :requests="['mapManagement.ProductSummary']" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {smarterPost} from '@helpers/vuex/data-loading'
import {groupBy, sumBy} from 'lodash'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import SimpleTable from '@components/table/SimpleTable'

export default {
  components: {
    SimpleTable,
    FrsLoadingIndicator,
    FrsRouterBackLink
  },
  mixins: [
    MirrorRouteMixin
  ],
  data () {
    return {
      data: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', [
      'mapIdsForDownload'
    ]),
    ...mapState('fieldRecordSystem', {
      orgUnitId: state => state.navigation.location.orgUnitId
    }),
    summaryData () {
      let data = []
      let groupedByProduct = groupBy(this.data, 'productName')
      const keys = Object.keys(groupedByProduct)
      keys.forEach(product => {
        const groupedByUnit = groupBy(groupedByProduct[product], 'unit')
        Object.keys(groupedByUnit).forEach(unit => {
          data.push({
            productName: product,
            sum: sumBy(groupedByUnit[unit], 'value'),
            unit: unit
          })
        })
      })
      return data
    },
    columnsDetails () {
      return {
        fieldName: this.$i18n.translate('frs.mapManagement.productSummary.fieldName'),
        orgUnitName: this.$i18n.translate('frs.mapManagement.productSummary.orgUnitName'),
        area: this.$i18n.translate('frs.mapManagement.productSummary.area'),
        productName: this.$i18n.translate('frs.mapManagement.productSummary.productName'),
        value: this.$i18n.translate('frs.mapManagement.productSummary.value'),
        unit: this.$i18n.translate('frs.mapManagement.productSummary.unit')
      }
    },
    columnsSummary () {
      return {
        productName: this.$i18n.translate('frs.mapManagement.productSummary.productName'),
        sum: this.$i18n.translate('frs.mapManagement.productSummary.sum'),
        unit: this.$i18n.translate('frs.mapManagement.productSummary.unit')
      }
    }
  },
  created () {
    smarterPost('/api/v2/map-management/orgunits/{orgUnitId}/application-map/product-summary', this.mapIdsForDownload, {
      inputs: {
        orgUnitId: () => this.orgUnitId
      },
      id: 'mapManagement.ProductSummary',
      onResult: productSummary => {
        this.data = productSummary
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.summary-container {
  display: flex;
  flex-direction: column;

  .table-container {
    margin: 8px;

    .summary-table {
      width: 60%;
    }
  }
}
</style>
